class AboutUsPage extends Component {

    static  name() {
        return "AboutUsPage";
    }

    static componentName() {
        return "AboutUsPage";
    }

    getTemplate() {
        return `<div>
                    <headerSectionComponent></headerSectionComponent>
                    <div class="static-page px-4 px-md-5" :class="classContainer">
                        <div class="row">
                            <h5 class="col-12 p-3 mb-4 title underlined-title">{{tr('About us')}}</h5>
                            <div class="col">
                                <htmlSectionComponent templateCode="inCompany" ></htmlSectionComponent>
                            </div>
                        </div>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

AboutUsPage.registerComponent();